/** @jsx jsx */

import { FC } from 'react'
import { jsx } from 'theme-ui'

import UniversalLink from '../components/universal-link'

interface TableListProps {
  containerStyles?: {}
  title: string
  items: {
    href: string
    title: string
  }[]
}

const TableList: FC<TableListProps> = ({ containerStyles, title, items }) => {
  return (
    <div id={title.toLocaleLowerCase().replace(` `, `-`)} sx={containerStyles}>
      <h2>{title}</h2>
      <ul
        sx={{
          my: 0,
          pl: 0,
          listStyleType: `none`,
          border: `1px solid`,
          borderColor: `gray2_slate9`,
        }}
      >
        {items.map(({ href, title }, index) => (
          <li
            key={title}
            sx={{
              borderTop: index ? `1px solid` : `none`,
              borderTopColor: `gray2_slate9`,
              bg: index % 2 === 0 ? `gray1_slate9` : `transparent`,
            }}
          >
            <UniversalLink
              {...{ href }}
              sx={{
                textDecoration: `none`,
                display: `inline-block`,
                py: 1,
                px: 2,

                [`:hover, :focus`]: {
                  textDecoration: `underline`,
                },
              }}
            >
              {title}
            </UniversalLink>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default TableList
