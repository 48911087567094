/** @jsx jsx */

import { jsx } from 'theme-ui'

import TableList from '../components/table-list'
import routes from '../constants/routes'

export const halo = {
  title: `Documents library`,
  route: routes.documents,
}

export const intro = {
  ...halo,
}

const lists = [
  {
    title: `Issuer resources`,
    items: [
      {
        href: routes.documentsNewIssuerListingChecklist,
        title: `New Issuer Listing Checklist`,
      },
    ],
  },

  {
    title: `Investor resources`,
    items: [
      {
        href: routes.documentsInvestorOnboardingChecklist,
        title: `Investor Onboarding Checklist`,
      },
    ],
  },

  {
    title: `Regulatory disclosures`,
    items: [
      {
        href: routes.documentsCcmxBusinessContinuityPlanDisclosureStatement,
        title: `CCMX Business Continuity Plan Disclosure Statement`,
      },
      {
        href: routes.documentsCcmxCashManagementAndSweepProgramDisclosures,
        title: `CCMX Cash Management & Sweep Program Disclosures`,
      },
      {
        href: routes.documentsCcmxCustomerComplaintsDisclosureStatement,
        title: `CCMX Customer Complaints Disclosure Statement`,
      },
      {
        href: routes.documentsCcmxCustomerRelationshipSummary,
        title: `CCMX Customer Relationship Summary`,
      },
      {
        href: routes.documentsCcmxESignAndElectronicDeliveryConsent,
        title: `CCMX E-Sign and Electronic Delivery Consent`,
      },
      {
        href: routes.documentsCcmxFinraPublicDisclosureProgram,
        title: `CCMX FINRA Public Disclosure Program`,
      },
      {
        href: routes.documentsCcmxFundsAvailabilityDisclosure,
        title: `CCMX Funds Availability Disclosure`,
      },
      {
        href: routes.documentsCcmxPermittedClientNoticeForCanadianInvestors,
        title: `CCMX Permitted Client Notice for Canadian Investors`,
      },
      {
        href: routes.documentsCcmxPrivacyPolicy,
        title: `CCMX Privacy Policy`,
      },
      {
        href: routes.documentsCcmxUsaPatriotActNotice,
        title: `CCMX USA PATRIOT Act Notice`,
      },
      {
        href: routes.documentsCcmxUseOfServicesAndRiskDisclosureStatement,
        title: `CCMX Use of Services and Risk Disclosure Statement`,
      },
    ],
  },

  {
    title: `Financial statements`,
    items: [
      {
        href: routes.documentsCcmxAuditReport123120,
        title: `CCMX Audit Report (12-31-20)`,
      },
    ],
  },
]

export const content = (
  <div sx={{ maxWidth: `var(--maxContentWidth)`, mx: `auto`, pt: 5, pb: 6, px: 4 }}>
    {lists.map((list, index) => (
      <TableList key={list.title} containerStyles={index && { mt: 5 }} {...list} />
    ))}
  </div>
)
