/** @jsx jsx */

import { FC } from 'react'
import { jsx } from 'theme-ui'

import Halo from '../../components/halo'
import Intro from '../../components/intro'
import Layout from '../../components/layout'
import { content, halo, intro } from '../../content/documents'

const Documents: FC = () => {
  return (
    <Layout>
      <Halo {...halo} />
      <Intro {...intro} />
      <section sx={{ bg: `white_slate8` }}>{content}</section>
    </Layout>
  )
}

export default Documents
